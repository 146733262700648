module.exports={
	"product_info_title": "UPlus產品介紹",
	"product_info": "我們的產品就屬於量化策略交易，全自動交易，不需要設置，不需要手動開平倉，將交易所API綁定到我們平台，授權交易權限就可以了。本金收益全在交易所，安全無風險。軟件免費使用，收益時扣取利潤分成，扣取每筆盈利的25%！我們沒有直接扣取利潤的權限，所以需要會員提前在APP充值一些USDT。充值的USDT可以用來充當燃料或是購買點卡。",
	"start_robot_condition": "啟動機器人需要什麼條件",
	"start_robot_condition_tip": "燃料或是點卡必須要大於等於本金的10%才可以啟動機器人",
	"Strategy_Instructions": "策略選擇說明",
	"Strategy_Instructions_tip": "策略分為合約和現貨兩種，合約賺USDT，現貨賺幣量。合約量化考慮策略的穩定性暫時只上線了部分幣種，不過交易賺取的是USDT，選哪個交易對關係不大。現貨適用於全部幣種！",
	"Contract_Trading_Instructions": "合約策略交易概括說明",
	"Contract_Trading_Instructions_tip": "交易策略簡單理解屬於多空對沖套利，通過“獨家數學模型”來精準把握買進及賣出機會，從而實現精細化的倉位管理、強大的風險控制和靈活的行情捕捉。從宏觀角度看，策略可適應絕大部分行情，通過在大部分行情裡的高收益覆蓋小概率行情中的虧損，以保證長期運行中的高收益率。",
	"Modify_position_description": "修改策略倉位說明",
	"Modify_position_description_tip": "當前修改倉位只可以增加不能減少。在當前策略找到修改策略，點擊修改策略，輸入新的USDT金額，點擊確認修改即可！。修改後新倉位可能不會立即生效，而是保證策略正常運行的情況下逐步更新完成。",
	"Stop_Loss_Instructions": "止損說明",
	"Stop_Loss_Instructions_tip_title1": "策略設置不止損：",
	"Stop_Loss_Instructions_tip_content1": "想要扛單至行情回調可選擇不止損。策略使用的是全倉保證金，可能會虧掉交易賬戶倉位設置以外的保證金，需留意風險。",
	"Stop_Loss_Instructions_tip_title2": "策略設置止損：",
	"Stop_Loss_Instructions_tip_content2": "1.策略風險較高時會平掉部分倉位以釋放保證金抵御風險，平掉倉位的浮虧會轉為實虧。後續盈利優先補虧損，補虧損不消耗燃料費及點卡；\n2.機器人會實時監控策略的風險水平，當風險較高時會自動止損，可能會觸發多次止損，最大止損總倉位的75%。",
	"Card_Fuel_Instructions": "點卡和燃料費說明",
	"Card_Fuel_Instructions_tip": "點卡跟燃料都是用來扣取利潤分成的。收益時扣取點卡或是燃料費來抵扣利潤。區別在於購買點卡時有折扣，但如果有剩餘是不能提走，而燃料沒有折扣有剩餘可以提走!",
	"Trading_account_loss": "交易賬戶浮虧說明",
	"Trading_account_loss_tip": "多空雙開的這種交易邏輯使得交易賬戶時而浮虧時而浮盈。這屬於正常。浮虧不能算做虧損的，如果是產生了實際的止損，會記錄虧損，之後每筆交易產生的利潤的70%會優先彌補虧損（在補虧損的過程中這部分利潤不扣取分成），直到虧損彌補完，剩餘利潤的30%仍然會扣取分成！",
	"Withdrawal_Instructions": "提現說明",
	"Withdrawal_Instructions_tip": "提現需人工審核及多次驗證，再透過信箱回覆確認訊息，回覆「確認」後7個工作天內到賬，假日順延！",
	"Exclusive_user_introduction": "專屬用戶介紹",
	"Exclusive_user_introduction_tip1": "Okx專屬用戶：通過OKX專屬鏈接",
	"Exclusive_user_introduction_tip2": "或邀請碼（UPLUS3）註冊的交易所會員。\n幣安專屬用戶：申請幣安API，通過UPlus平台校驗並綁定成功的用戶。\n專屬用戶權益：專屬用戶享受限時98%燃料費折扣。",
	"Deduction_Instructions": "扣費說明",
	"Deduction_Instructions_tip": "軟件免費使用，收益時扣取利潤分成，扣取每筆盈利的25%！我們沒有直接扣取利潤的權限，所以需要會員提前在APP充值一些USDT。充值的USDT可以用來充當燃料或是購買點卡。點卡跟燃料都是用來扣取利潤分成的！",
	"must_read_newbies": "新手必讀",
	"must_read_newbies_content1_1": "1.合約交易策略屬於高頻對沖交易，不判斷行情，不猜漲跌，通過價格的波動持續套利，所以本金可能會有一定的浮動，一般在20%以內，浮動多少跟行情相關，持續運行本金會慢慢變多，新手能接受再開啟。",
	"must_read_newbies_content1_2": "切勿經常手動操作會影響機器人運行導致收益降低還會增大風險。",
	"must_read_newbies_content1_3": "另外購買點卡後不能退款，所以決定長期使用後再購買點卡，可以先充值到燃料賬戶啟動運行。",
	"must_read_newbies_content2_0": "2.請謹慎在交易所手動挪用資金，",
	"must_read_newbies_content2": "劃轉後會導致機器人交易異常，無法正常開單，嚴重情況會有爆倉風險。",
	"must_read_newbies_content3": "3.機器人累計倉位是複雜和緩慢的，系統會自動在較長周期內佈局，對利潤有精確的計算，新手不要頻繁刪除機器人重新添加，沒有浮虧不代表已經將盈利全部結算，倉位就是未來的利潤，沒有倉位就沒有利潤。當行情來的時候失去了更多盈利的倉位利潤就會降低許多。"
}
